import { makeRequest, donify, isMessageFromSameOrigin, isObjectEmpty, isBoolean } from './util';
import { validateAvailableSizes } from './client/validation';
import * as client from './client';

const DEFAULT_MONITORING_PARAMS = {
  activate_sdk_sentry: false,
  log_sentry_errors_to_console: false,
  activate_sending_event: false,
  log_events_to_console: false,
  log_sentry_infos_to_console: false,
};

class Fitle {
  // For backward compatibility
  init({ client_id, brand_id, language }) {
    // brand_id for backward comp (LeazBoutique)
    this.brand_id = brand_id;
    // back comp (Seidendensticker)
    this.language = language;
    this.client_id = client_id;
    return this;
  }

  setup_default() {
    this.monitoring_params = DEFAULT_MONITORING_PARAMS;

    this.plugin_infos = {};
    this.plugin_custom_behaviour = {};
  }

  async setup(client_id, monitoring_params_override) {
    if (!this.is_setup_done) {
      this.client_id = client_id;
      this.plugin_infos = {};
      this.plugin_custom_behaviour = {};

      const sentry_params = await this.getSentryBehaviour();
      const tracking_params = await this.getTrackingBehaviour();
      const monitoring_params = {
        ...sentry_params,
        ...tracking_params,
      };

      if (monitoring_params_override) {
        // This is for half manual integrations So we can activate monitoring
        // only from scripts that we made and are loaded in client website
        for (const key in DEFAULT_MONITORING_PARAMS) {
          if (isBoolean(monitoring_params_override[key])) {
            monitoring_params[key] = monitoring_params_override[key];
          }
        }
      }
      this.monitoring_params = monitoring_params;
      this.is_setup_done = true;
    }
  }

  async getPluginCustomization(client_id) {
    if (isObjectEmpty(this.plugin_custom_behaviour)) {
      const pluginCustomizationRaw = await makeRequest(
        'GET',
        `${process.env.API_URL}/v2/plugins/customization?client_id=${client_id}`,
      );
      this.sendSentryBreadcrumb('Got plugin info response');
      const pluginCustomization = JSON.parse(pluginCustomizationRaw);
      this.sendSentryBreadcrumb('Plugin info parsed');

      this.plugin_custom_behaviour = pluginCustomization['theme'];
      return pluginCustomization['theme'];
    }
    return this.plugin_custom_behaviour;
  }

  async getSentryBehaviour() {
    const plugin_customization = await this.getPluginCustomization(this.client_id);
    const { activate_sdk_sentry, log_sentry_errors_to_console, log_sentry_infos_to_console } =
      plugin_customization;
    return {
      activate_sdk_sentry,
      log_sentry_errors_to_console,
      log_sentry_infos_to_console,
    };
  }

  async getTrackingBehaviour() {
    const plugin_customization = await this.getPluginCustomization(this.client_id);
    const { activate_sending_event, log_events_to_console } = plugin_customization;
    return {
      activate_sending_event,
      log_events_to_console,
    };
  }

  // Add done property for backward compatibility
  widget(parameters) {
    const widgetPromise = this.createWidget(parameters);
    return donify(widgetPromise);
  }

  async getPluginInfos(client_id, product_id) {
    if (!this.plugin_infos) this.plugin_infos = {};
    if (!this.plugin_infos[product_id]) {
      const pluginInfoResponse = await makeRequest(
        'GET',
        `${process.env.API_URL}/v1/products/plugin_info?client_id=${client_id}&client_product_id=${product_id}`,
      );
      this.sendSentryBreadcrumb('Got plugin info response');
      const pluginInfo = JSON.parse(pluginInfoResponse);
      this.sendSentryBreadcrumb('Plugin info parsed');

      this.plugin_infos[product_id] = pluginInfo;
      return pluginInfo;
    }
    return this.plugin_infos[product_id];
  }

  async createWidget(parameters) {
    await this.setup(parameters.client_id);
    let pluginInfo;
    try {
      this.sendSentryBreadcrumb('Creating widget');
      const {
        product_id,
        product_image,
        end_behavior,
        available_sizes,
        target,
        text,
        unit_system,
        shop_country,
        tooltip_position,
        bra_input_mandatory,
      } = parameters;

      let client_hostname
      if (!parameters.client_hostname) {
        client_hostname = window.location.host
      }
      this.sendSentryBreadcrumb('Got parameters (target not shown)', {
        product_id,
        product_image,
        end_behavior,
        available_sizes,
        text,
        unit_system,
        shop_country,
        client_id: parameters.client_id,
        language: parameters.language,
        tooltip_position,
        bra_input_mandatory,
        client_hostname,
      });

      let client_id;
      if (parameters.client_id) client_id = parameters.client_id;
      else if (this.client_id) client_id = this.client_id;
      else if (this.brand_id) {
        this.sendSentryBreadcrumb('Getting brand id from client id', {
          brand_id: this.brand_id,
        });
        client_id = await makeRequest(
          'GET',
          `${process.env.API_URL}/v1/brands/${this.brand_id}/client`,
        );
      } else throw new Error('No client_id or brand_id');
      // back comp Seidensticker
      let language;
      if (parameters.language) language = parameters.language;
      else if (this.language) language = this.language;
      else throw new Error('No language');

      // According to the AB test don't create the widget
      // NB this must be after product event to get correct analytics for AB test
      const variations = await this._getABData(client_id);
      this.sendSentryBreadcrumb('Got variations', variations);
      if (
        variations &&
        variations['Fitle impact'] &&
        variations['Fitle impact']['variation'] === 'test'
      ) {
        return;
      }
      const pluginInfo = await this.getPluginInfos(client_id, product_id);

      if (!pluginInfo.eligible) {
        this.sendSentryBreadcrumb('Product not eligible');
        console.warn(
          `[Fitle] Product unknown or not associated to a sizing chart (id: ${product_id})`,
        );
        return;
      }
      let widget = null;

      const {
        social_proof,
        shoe_shape_slug,
        widget_customization,
        gender,
        measurement_names,
        body_part,
        sizes,
        sizing_chart_slug,
        comfort_renderer_theme,
        has_references_step,
      } = pluginInfo;
      if (social_proof) {
        this.sendSentryBreadcrumb('Creating iframe');

        const iframe = await client.createIframe();
        this.sendSentryBreadcrumb('Creating widget');

        widget = new client.Widget(
          {
            client_id,
            product_id,
            product_image,
            shoe_shape_slug,
            end_behavior,
            available_sizes,
            language,
            shop_country,
            unit_system,
            text,
            tooltip_position,
            bra_input_mandatory,
            social_proof,
            gender,
            body_part,
            sizes,
            has_references_step,
            client_hostname,
          },
          target,
          iframe,
          widget_customization,
        );
        this.sendSentryBreadcrumb('Widget created');
        widget.activate();
        this.sendSentryBreadcrumb('Widget activated');

        return widget;
      } else {
        const availableSizesValidity = validateAvailableSizes(available_sizes, sizes);
        let dismissAvailableSizes = false;
        if (availableSizesValidity === 'INVALID') {
          makeRequest(
            'PUT',
            `${process.env.API_URL}/wrong_sizing_chart_products?client_id=${client_id}&client_product_id=${product_id}`,
          );
          return null;
        } else {
          // Send product event so it has a tracking id
          // For clients with the old analytics integration, the first event
          // product sent from the page has no cookie (since it hasn't be set yet).
          // With this, we send the event product two times for old integration but it's OK.
          this.sendEvent({ event: 'product', product_id, client_id, shop_country });
          this.sendSentryBreadcrumb('Sent product event');

          if (availableSizesValidity === 'SOMEHOW_VALID') {
            dismissAvailableSizes = true;
          }
        }
        this.sendSentryBreadcrumb('Creating iframe');

        const iframe = await client.createIframe();
        this.sendSentryBreadcrumb('Creating widget');
        widget = new client.Widget(
          {
            client_id,
            product_id,
            product_image,
            shoe_shape_slug,
            sizing_chart_slug,
            end_behavior,
            available_sizes: dismissAvailableSizes ? null : available_sizes,
            gender,
            language,
            shop_country,
            unit_system,
            text,
            measurement_names,
            body_part,
            sizes,
            tooltip_position,
            bra_input_mandatory,
            has_references_step,
            client_hostname,
          },
          target,
          iframe,
          widget_customization,
          comfort_renderer_theme,
        );
        this.sendSentryBreadcrumb('Widget created');
        widget.activate();
        this.sendSentryBreadcrumb('Widget activated');
        return widget;
      }
    } catch (error) {
      delete parameters.target; // HTML elements cannot be cloned
      this.sendSentryError(error, {
        pluginInfo,
        parameters,
      });
    }
  }

  async sendEvent(data) {
    if (this.monitoring_params['activate_sending_event']) {
      const iframe = await client.createIframe();
      iframe.post('FTL_SEND_EVENT', data);
    }
    if (this.monitoring_params['log_events_to_console']) {
      // we just log events in console
      console.info(
        '[Fitle] Logging events in console, (This must be just for debuging) event=',
        data,
      );
    }
  }

  async sendDebugEvent(data) {
    if (this.monitoring_params['activate_sending_event']) {
      const iframe = await client.createIframe();
      iframe.post('FTL_SEND_DEBUG_EVENT', data);
    }
    if (this.monitoring_params['log_events_to_console']) {
      // we just log events in console
      console.info(
        '[Fitle] Logging debug events in console, (This must be just for debuging) event=',
        data,
      );
    }
  }

  async sendMixpanelEvent(event, data) {
    const iframe = await client.createIframe();
    iframe.post('FTL_SEND_MIXPANEL_EVENT', event, data);
  }

  async sendSentryBreadcrumb(message, data) {
    if (this.monitoring_params['activate_sdk_sentry']) {
      const iframe = await client.createIframe();
      iframe.post('FTL_SEND_SENTRY_BREADCRUMB', { category: 'sdk', message, data });
    }
    if (this.monitoring_params['log_sentry_infos_to_console']) {
      // we just log errors in console
      console.info('[Fitle] Logging infos in console, (This must be just for debuging) info=', {
        message,
        data,
      });
    }
  }

  async sendSentryError(error, errorInfo) {
    if (this.monitoring_params['activate_sdk_sentry']) {
      const iframe = await client.createIframe();
      iframe.post('FTL_SEND_SENTRY_ERROR', {
        errorMessage: error.message,
        errorInfo: { ...errorInfo, _errorStack: error.stack },
      });
    }
    if (this.monitoring_params['log_sentry_errors_to_console']) {
      // we just log errors in console
      console.error(
        '[Fitle] Logging errors in console, (This must be just for debuging) error=',
        error.message,
      );
    }
  }

  async resetUserInfo() {
    const iframe = await client.createIframe();
    iframe.post('FTL_RESET_USER_INFO');
  }

  async _getABData(clientId) {
    const iframe = await client.createIframe();
    return new Promise(resolve => {
      window.addEventListener('message', function onABDataFitleMessage(messageEvent) {
        try {
          const { data, origin } = messageEvent;

          if (!isMessageFromSameOrigin(origin)) return;

          const message = data || {};
          if (message.from === 'FTL_MESSAGE' && message.event === 'VARIATIONS') {
            resolve(message.payload);
            window.removeEventListener('message', onABDataFitleMessage);
          }
        } catch (error) {
          this.sendSentryBreadcrumb(`Error getting AB tests data`);
          this.sendSentryError(error, { clientId });
        }
      });
      iframe.post('FTL_GET_AB_DATA', { clientId });
    });
  }

  async _setTrackingId(trackingId) {
    const iframe = await client.createIframe();
    iframe.post('FTL_SET_TRACKING_ID', trackingId);
  }
  async _getTrackingId() {
    const iframe = await client.createIframe();
    return new Promise(resolve => {
      window.addEventListener('message', function onFitleMessage(messageEvent) {
        try {
          const { data, origin } = messageEvent;

          if (!isMessageFromSameOrigin(origin)) return;

          const message = data || {};
          if (message.from === 'FTL_MESSAGE' && message.event === 'TRACKING_ID') {
            resolve(message.payload);
            window.removeEventListener('message', onFitleMessage);
          }
        } catch (error) {
          this.sendSentryBreadcrumb(`Error getting tracking id`);
          this.sendSentryError(error);
        }
      });
      iframe.post('FTL_GET_TRACKING_ID');
    });
  }
}

// This object is for half manual integration (client and fitle both make the integration)
window.FitleClientInte = new Fitle();
window.FitleClientInte.setup_default();

window.Fitle = new Fitle();
window.Fitle.setup_default();

window.Fitle._getTrackingId()
  .then(tracking_id => {
    localStorage.setItem('ftl_device_id', tracking_id);
  })
  .catch(error => {
    this.sendSentryBreadcrumb(`Error setting tracking id`);
    this.sendSentryError(error);
  });
